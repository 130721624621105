import { Routes, Route, Navigate } from "react-router-dom";
import React, { Suspense } from "react";

// lazy load component
const lazy = (loader) => (props) => {
  const Component = React.lazy(loader);
  return (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );
};

// Page Compoent
const Standalone = lazy(() => import("./Standalone"));
const Leaflet = lazy(() => import("./Leaflet"));

const routes = () => (
  <Routes>
    <Route>
      <Route index element={<Standalone />} />
      <Route path="/leaflet" element={<Leaflet />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
  </Routes>
);

export default routes;
